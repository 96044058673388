export const zones = [
  {
    id: 'PARIS',
    name: 'Paris',
    cornerNW: { latitude: 48.93651176701517, longitude: 2.151506593360409 },
    cornerSE: { latitude: 48.794001357725215, longitude: 2.500322511329159 },
    deliveryFee: 5,
    zipcodes: {
      75001: { label: 'Paris 1' },
      75002: { label: 'Paris 2' },
      75003: { label: 'Paris 3' },
      75004: { label: 'Paris 4' },
      75005: { label: 'Paris 5' },
      75006: { label: 'Paris 6' },
      75007: { label: 'Paris 7' },
      75008: { label: 'Paris 8' },
      75009: { label: 'Paris 9' },
      75010: { label: 'Paris 10' },
      75011: { label: 'Paris 11' },
      75012: { label: 'Paris 12' },
      75013: { label: 'Paris 13' },
      75014: { label: 'Paris 14' },
      75015: { label: 'Paris 15' },
      75016: { label: 'Paris 16' },
      75017: { label: 'Paris 17' },
      75018: { label: 'Paris 18' },
      75019: { label: 'Paris 19' },
      75020: { label: 'Paris 20' },
      75116: { label: 'Paris 16 (2)' },
      78000: { deliveryFee: 15, label: 'Versailles' },
      78150: { deliveryFee: 15, label: 'Chesnay' },
      91300: { deliveryFee: 15, label: 'Massy' },
      92000: { deliveryFee: 15, label: 'Nanterre' },
      92100: { label: 'Boulogne-Billancourt' },
      92110: { label: 'Clichy' },
      92120: { label: 'Montrouge' },
      92130: { label: 'Issy-les-Moulineaux' },
      92140: { deliveryFee: 15, label: 'Clamart' },
      92150: { deliveryFee: 15, label: 'Suresnes' },
      92160: { deliveryFee: 15, label: 'Antony' },
      92170: { label: 'Vanves (1)' },
      92190: { deliveryFee: 15, label: 'Meudon (1)' },
      92200: { label: 'Neuilly' },
      92210: { deliveryFee: 15, label: 'St Cloud' },
      92240: { label: 'Malakoff, Vanves (2)' },
      92250: { deliveryFee: 15, label: 'La Garenne Colombes' },
      92270: { deliveryFee: 15, label: 'Bois Colombes' },
      92300: { label: 'Levallois' },
      92320: { label: 'Chatillon' },
      92330: { deliveryFee: 15, label: 'Sceaux' },
      92350: { deliveryFee: 15, label: 'Le Plessis-Robinson' },
      92360: { deliveryFee: 15, label: 'Meudon (2)' },
      92380: { deliveryFee: 15, label: 'Garches' },
      92400: { deliveryFee: 15, label: 'Courbevoie' },
      92410: { deliveryFee: 15, label: 'Ville d\'Avray' },
      92420: { deliveryFee: 15, label: 'Vaucresson' },
      92430: { deliveryFee: 15, label: 'Marnes la Coquette' },
      92500: { deliveryFee: 15, label: 'Rueil-malmaison' },
      92600: { deliveryFee: 15, label: 'Asnières' },
      92800: { deliveryFee: 15, label: 'Puteaux' },
      93100: { label: 'Montreuil' },
      93260: { label: 'Les lilas' },
      93310: { label: 'Le Pré-Saint-Gervais' },
      93400: { label: 'Saint-Ouen' },
      94160: { label: 'Saint Mandé' },
      94200: { label: 'Ivry-sur-Seine' },
      94205: { label: 'Ivry-sur-Seine' },
      94220: { label: 'Charenton le pont' },
      94240: { deliveryFee: 15, label: 'L\'Haÿ-les-Roses' },
      94250: { label: 'Kremlin-Bicetre (1), Gentilly' },
      94260: { deliveryFee: 15, label: 'Fresnes' },
      94270: { label: 'Kremlin-Bicetre (2)' },
      94300: { label: 'Vincennes' },
      94400: { label: 'Vitry sur Seine' },
      94410: { label: 'Maison Alfort (1)' },
      94700: { label: 'Maison Alfort (2)' },
      94800: { label: 'Villejuif' },
    },
  },
  {
    id: 'MARSEILLE',
    name: 'Marseille',
    deliveryFee: 5,
    cornerNW: { latitude: 43.36275505192238, longitude: 5.334924519423453 },
    cornerSE: { latitude: 43.19760956070986, longitude: 5.479812310247439 },
    zipcodes: {
      // Marseille except the 3rd, 14th, 15th and 16th arrondissements
      13001: {},
      13002: {},
      13004: {},
      13005: {},
      13006: {},
      13007: {},
      13008: {},
      13009: {},
      13010: {},
      13011: {},
      13012: {},
      13013: {},
    },
  },
  {
    id: 'LYON',
    name: 'Lyon',
    deliveryFee: 5,
    cornerNW: { latitude: 45.803251, longitude: 4.776678 },
    cornerSE: { latitude: 45.698921, longitude: 4.902279 },
    zipcodes: {
      69001: {}, // Lyon 1er arrondissement
      69002: {}, // Lyon 2eme arrondissement
      69003: {}, // Lyon 3eme arrondissement
      69004: {}, // Lyon 4eme arrondissement
      69005: {}, // Lyon 5eme arrondissement
      69006: {}, // Lyon 6eme arrondissement
      69007: {}, // Lyon 7eme arrondissement
      69008: {}, // Lyon 8eme arrondissement
      69009: {}, // Lyon 9eme arrondissement
      69100: { label: 'Villeurbane' },
      69110: { label: 'St Foy les Lyon' },
    },
  },
  {
    id: 'SEINE_ET_MARNE',
    name: 'Seine-et-Marne',
    deliveryFee: 5,
    cornerNW: { latitude: 48.83180441791672, longitude: 2.4954817571582453 },
    cornerSE: { latitude: 48.78878953365783, longitude: 2.6187558743142745 },
    zipcodes: {
      77090: { label: 'Collégien' },
      77111: { label: 'Soignolles-en-Brie, Solers' },
      77135: { label: 'Pontcarré' },
      77144: { label: 'Chalifert, Montévrain' },
      77150: { label: 'Férolles-Attilly, Lésigny' },
      77163: { label: 'Dammartin-sur-Tigeaux, Mortcerf, Tigeaux' },
      77164: { label: 'Ferrières-en-Brie' },
      77166: { label: 'Évry-Grégy-sur-Yerre, Grisy-Suisnes' },
      77170: { label: 'Brie-Comte-Robert, Servon, Coubert' },
      77173: { label: 'Chevry-Cossigny' },
      77174: { label: 'Villeneuve-le-Comte, Villeneuve-Saint-Denis' },
      77177: { label: 'Brou-sur-Chantereine' },
      77181: { label: 'Courtry, Le Pin' },
      77183: { label: 'Croissy-Beaubourg' },
      77184: { label: 'Émerainville' },
      77185: { label: 'Lognes' },
      77186: { label: 'Noisiel' },
      77200: { label: 'Torcy' },
      77220: { label: 'Favières, Gretz-Armainvilliers, Liverdy-en-Brie, Presles-en-Brie, Tournan-en-Brie' },
      77330: { label: 'Ozoir-la-Ferrière' },
      77340: { label: 'Pontault-Combault' },
      77360: { label: 'Vaires-sur-Marne' },
      77400: { label: 'Carnetin, Dampmart, Gouvernes, Lagny-sur-Marne, Pomponne, Saint-Thibault-des-Vignes, Thorigny-sur-Marne' },
      77410: { label: 'Annet-sur-Marne, Charmentray, Charny, Claye-Souilly, Fresnes-sur-Marne, Gressy, Messy, Précy-sur-Marne, Saint-Mesmes, Villeroy, Villevaudé' },
      77420: { label: 'Champs-sur-Marne' },
      77500: { label: 'Chelles' },
      77600: { label: 'Bussy-Saint-Martin, Chanteloup-en-Brie, Conches-sur-Gondoire, Guermantes, Jossigny, Bussy-Saint-Georges' },
      77610: { label: 'Les Chapelles-Bourbon, Châtres, Crèvecœur-en-Brie, Fontenay-Trésigny, La Houssaye-en-Brie, Marles-en-Brie, Neufmoutiers-en-Brie' },
      77680: { label: 'Roissy-en-Brie' },
      77700: { label: 'Bailly-Romainvilliers, Chessy, Coupvray, Magny-le-Hongre, Serris' },
      93160: { label: 'Noisy-le-Grand' },
      94350: { label: 'Villiers-sur-Marne' },
      94360: { label: 'Bry-sur-Marne' },
      94420: { label: 'Le Plessis-Trévise' },
      94430: { label: 'Chennevières-sur-Marne' },
      94440: { label: 'Santeny, Marolles-en-Brie' },
      94490: { label: 'Ormesson-sur-Marne' },
      94500: { label: 'Champigny-sur-Marne' },
      94510: { label: 'La Queue-en-Brie' },
      94520: { label: 'Mandres-les-Roses, Périgny' },
      94880: { label: 'Noiseau' },
    },
  },
  {
    id: 'AIX_EN_PROVENCE',
    name: 'Aix-en-Provence',
    deliveryFee: 5,
    cornerNW: { latitude: 43.62037483233502, longitude: 5.295963901626578 },
    cornerSE: { latitude: 43.456147, longitude: 5.504146 },
    zipcodes: {
      13080: { label: 'Aix, Luynes' },
      13090: { label: 'Aix' },
      13100: { label: 'Aix, le Tholonet, Saint-Marc-Jaumegarde' },
      13120: { deliveryFee: 15, label: 'Gardanne' },
      13122: { deliveryFee: 15, label: 'Ventabrun' },
      13290: { label: 'Aix, les Milles' },
      13320: { deliveryFee: 15, label: 'Bouc bel air' },
      13480: { deliveryFee: 15, label: 'Cabriès' },
      13510: { deliveryFee: 15, label: 'Eguilles' },
      13540: { label: 'Aix, Puyricard' },
      13590: { deliveryFee: 15, label: 'Meyreuil' },
      13770: { deliveryFee: 15, label: 'Venelles' },
    },
  },
  {
    id: 'METZ',
    name: 'Metz',
    deliveryFee: 10,
    cornerNW: { latitude: 49.157096, longitude: 6.107081 },
    cornerSE: { latitude: 49.059383, longitude: 6.275795 },
    zipcodes: {
      57000: { label: 'Metz' },
      57050: { label: 'Metz, Plappeville, Vantoux, Ban St Martin, Longeville,' },
      57070: { label: 'Metz, St Julien, La Grange aux bois' },
      57140: { label: 'Woippy, la Maxe' },
      57151: { label: 'Marly' },
      57158: { label: 'Montigny (2)' },
      57160: { label: 'Scy-Chazelles, Moulins,' },
      57245: { label: 'Peltre' },
      57280: { label: 'Semécourt' },
      57950: { label: 'Montigny' },
    },
  },
  {
    id: 'BORDEAUX',
    name: 'Bordeaux',
    deliveryFee: 5,
    cornerNW: { latitude: 44.919193, longitude: -0.687469 },
    cornerSE: { latitude: 44.795899, longitude: -0.512567 },
    zipcodes: {
      33000: {}, // Bordeaux
      33100: {}, // Bordeaux
      33200: {}, // Bordeaux
      33300: {}, // Bordeaux
      33800: {}, // Bordeaux
    },
  },
];

export const validZipcodes = zones
  .reduce((currentZipcodes, zone) => currentZipcodes.concat(Object.keys(zone.zipcodes)), []);

export const getZone = zipcode => zones.find(zone =>
  Object.keys(zone.zipcodes).includes(zipcode));

export const getDeliveryFees = (zipcode) => {
  const zone = getZone(zipcode);
  if (!zone) return undefined;
  const zipcodeDeliveryFee = zone.zipcodes[zipcode].deliveryFee;
  if (typeof zipcodeDeliveryFee === 'number') {
    return zipcodeDeliveryFee;
  }
  return getZone(zipcode).deliveryFee;
};

export function isInZone({ latitude, longitude }, zone) {
  const { cornerNW, cornerSE } = zone;
  return cornerNW.latitude >= latitude && latitude >= cornerSE.latitude &&
    cornerNW.longitude <= longitude && longitude <= cornerSE.longitude;
}

export function checkPosition({ latitude, longitude }, zonesToCheck = zones) {
  return zonesToCheck
    .reduce((response, zone) => response || isInZone({ latitude, longitude }, zone), false);
}

export default function checkError(zipcode) {
  if (typeof zipcode !== 'string' || zipcode.length !== 5) {
    return {
      message: 'Merci d\'entrer un code postal à 5 chiffres',
      code: 1,
    };
  }
  if (!validZipcodes.includes(zipcode)) {
    return {
      message: 'Votre ville n\'est pas encore couverte par notre service. Voici la liste des villes couvertes pour le moment : Paris et une partie de l‘Ile de France, Marseille, Lyon, Bordeaux et Aix-en-Provence.', // eslint-disable-line max-len
      code: 2,
    };
  }
  return {
    message: '',
    code: 0,
  };
}
