// *** Version: 2 ***

/* eslint max-len: 0 */

// #####################################################################
// # @README before updating price:                                    #
// # * don't change the id of an item                                  #
// # * don't delete an item, set `hidden: true`                        #
// # * update version at the top of the file and in metadata object    #
// # * @TODO: update price on tilli-site                               #
// #####################################################################

const metadata = {
  minOrderAmount: 20,
  deliveryFee: 5,
  version: 2,
};

const pricing = [
  {
    name: 'Manteau & veste',
    slug: 'mant',
    types: [],
    categories: [
      {
        name: 'Cintrer',
        items: [
          { name: 'Épaules (x2)', price: 47, liningUnitPrice: 15, id: '2.mant-cin-1' },
          { name: 'Manches (x2)', price: 59, liningUnitPrice: 0, id: '2.mant-cin-2' },
          { name: 'Taille', price: 41, liningUnitPrice: 13, id: '2.mant-cin-3' },
          { name: 'Dos', price: 20, liningUnitPrice: 7, id: '2.mant-cin-4' },
        ],
      },
      {
        name: 'Élargir',
        items: [
          { name: 'Épaules (x2)', price: 52, liningUnitPrice: 18, id: '2.mant-ela-1' },
          { name: 'Manches (x2)', price: 64, liningUnitPrice: 0, id: '2.mant-ela-2' },
          { name: 'Taille', price: 46, liningUnitPrice: 15, id: '2.mant-ela-3' },
          { name: 'Dos', price: 25, liningUnitPrice: 8, id: '2.mant-ela-4' },
        ],
      },
      {
        name: 'Raccourcir',
        items: [
          { name: 'Longueur', price: 29, liningUnitPrice: 12, id: '2.mant-rac-1' },
          { name: 'Manche (x2 - par le bas)', price: 25, liningUnitPrice: 25, id: '2.mant-rac-2' },
          { name: 'Manche (x2 - par l\'épaule)', price: 95, liningUnitPrice: 25, id: '2.mant-rac-3' },
        ],
      },
      {
        name: 'Rallonger',
        items: [
          { name: 'Longueur', price: 41, liningUnitPrice: 12, id: '2.mant-ral-1' },
          { name: 'Manche (x2 - par le bas)', price: 27, liningUnitPrice: 25, id: '2.mant-ral-2' },
          { name: 'Manche (x2 - par l\'épaule)', price: 98, liningUnitPrice: 25, id: '2.mant-ral-3' },
        ],
      },
      {
        name: 'Broderie main',
        items: [
          { name: 'Initiales', price: 20, id: '2.mant-bro-1', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', price: 30, id: '2.mant-bro-2', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', price: 70, id: '2.mant-bro-3', multiple: true },
        ],
      },
      {
        name: 'Divers',
        items: [
          { name: 'Doublure : ajout', price: 96, id: '2.mant-div-1' },
          { name: 'Doublure : changement long', price: 149, id: '2.mant-div-2' },
          { name: 'Doublure : changement mi-long', price: 102, id: '2.mant-div-3' },
          { name: 'Coudières (x2) : ajout / remplacement', price: 24, id: '2.mant-div-4' },
          { name: 'Épaulettes (x2) : ajout / remplacement / retrait', price: 50, liningUnitPrice: 17, id: '2.mant-div-5' },
          { name: 'Zip : ajout/remplacement', price: 50, id: '2.mant-div-6', multiple: true },
          { name: 'Zip : retrait', price: 38, id: '2.mant-div-7', multiple: true },
          { name: 'Poche (x1) : changement de doublure', price: 20, id: '2.mant-div-8', multiple: true },
          { name: 'Poche (x1) : ajout / remplacement', price: 30, id: '2.mant-div-9', multiple: true },
          { name: 'Col : ajout / remplacement', price: 66, id: '2.mant-div-10' },
          { name: 'Boutonnière (petite) : création', price: 68, liningUnitPrice: 20, id: '2.mant-div-11' },
          { name: 'Boutonnière (grande) : création', price: 136, liningUnitPrice: 40, id: '2.mant-div-12' },
          { name: 'Bouton (x1) : ajout / remplacement', price: 3, id: '2.mant-div-13', multiple: true },
          { name: 'Déchirure : réparation', price: 8, id: '2.mant-div-14', multiple: true },
          { name: 'Passant : ajout / remplacement', price: 10, id: '2.mant-div-15', multiple: true },
          { name: 'Couture d\'appoint ou décousue - simple', price: 7, id: '2.mant-div-16', multiple: true },
          { name: 'Couture d\'appoint ou décousue - complexe', price: 15, id: '2.mant-div-17', multiple: true },
          { name: 'Couture complexe avec empiècement', price: 28, id: '2.mant-div-18', multiple: true },
        ],
      },
    ],
  },
  {
    name: 'Haut',
    slug: 'haut',
    types: [
      { slug: 'SIMPLE', name: 'basique' },
      { slug: 'COMPLEX', name: 'habillé' },
    ],
    categories: [
      {
        name: 'Cintrer',
        items: [
          { name: 'Épaules (x2)', type: 'SIMPLE', price: 14, id: '2.chem-cin-1' },
          { name: 'Manches (x2)', type: 'SIMPLE', price: 14, id: '2.chem-cin-2' },
          { name: 'Taille', type: 'SIMPLE', price: 14, id: '2.chem-cin-3' },
          { name: 'Dos', type: 'SIMPLE', price: 9, id: '2.chem-cin-4' },
          { name: 'Épaules (x2)', type: 'COMPLEX', price: 29, id: '2.chem-cin-5' },
          { name: 'Manches (x2)', type: 'COMPLEX', price: 29, id: '2.chem-cin-6' },
          { name: 'Taille', type: 'COMPLEX', price: 29, id: '2.chem-cin-7' },
          { name: 'Dos', type: 'COMPLEX', price: 18, id: '2.chem-cin-8' },
        ],
      },
      {
        name: 'Élargir',
        items: [
          { name: 'Épaules (x2)', type: 'SIMPLE', price: 15, id: '2.chem-ela-1' },
          { name: 'Manches (x2)', type: 'SIMPLE', price: 15, id: '2.chem-ela-2' },
          { name: 'Taille', type: 'SIMPLE', price: 15, id: '2.chem-ela-3' },
          { name: 'Dos', type: 'SIMPLE', price: 10, id: '2.chem-ela-4' },
          { name: 'Épaules (x2)', type: 'COMPLEX', price: 32, id: '2.chem-ela-5' },
          { name: 'Manches (x2)', type: 'COMPLEX', price: 32, id: '2.chem-ela-6' },
          { name: 'Taille', type: 'COMPLEX', price: 32, id: '2.chem-ela-7' },
          { name: 'Dos', type: 'COMPLEX', price: 20, id: '2.chem-ela-8' },
        ],
      },
      {
        name: 'Raccourcir',
        items: [
          { name: 'Longueur', type: 'SIMPLE', price: 12, id: '2.chem-rac-1' },
          { name: 'Manches (x2 - par le bas)', type: 'SIMPLE', price: 10, id: '2.chem-rac-2' },
          { name: 'Manches (x2 - par l\'épaule)', type: 'SIMPLE', price: 30, id: '2.chem-rac-3' },
          { name: 'Longueur', type: 'COMPLEX', price: 25, id: '2.chem-rac-4' },
          { name: 'Manches (x2 - par le bas)', type: 'COMPLEX', price: 29, id: '2.chem-rac-5' },
          { name: 'Manches (x2 - par l\'épaule)', type: 'COMPLEX', price: 85, id: '2.chem-rac-6' },
        ],
      },
      {
        name: 'Rallonger',
        items: [
          { name: 'Longueur', type: 'SIMPLE', price: 12, id: '2.chem-ral-1' },
          { name: 'Manches (x2 - par le bas)', type: 'SIMPLE', price: 10, id: '2.chem-ral-2' },
          { name: 'Manches (x2 - par l\'épaule)', type: 'SIMPLE', price: 30, id: '2.chem-ral-3' },
          { name: 'Longueur', type: 'COMPLEX', price: 25, id: '2.chem-ral-4' },
          { name: 'Manches (x2 - par le bas)', type: 'COMPLEX', price: 29, id: '2.chem-ral-5' },
          { name: 'Manches (x2 - par l\'épaule)', type: 'COMPLEX', price: 85, id: '2.chem-ral-6' },
        ],
      },
      {
        name: 'Broderie main',
        items: [
          { name: 'Initiales', type: 'SIMPLE', price: 15, id: '2.chem-bro-1', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'SIMPLE', price: 22, id: '2.chem-bro-2', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'SIMPLE', price: 45, id: '2.chem-bro-3', multiple: true },
          { name: 'Initiales', type: 'COMPLEX', price: 15, id: '2.chem-bro-4', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'COMPLEX', price: 22, id: '2.chem-bro-5', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'COMPLEX', price: 45, id: '2.chem-bro-6', multiple: true },
        ],
      },
      {
        name: 'Divers',
        items: [
          { name: 'Poignets (x2) : remplacement / changement', type: 'SIMPLE', price: 12, id: '2.chem-div-1' },
          { name: 'Coudières (x2) : ajout / remplacement', type: 'SIMPLE', price: 16, id: '2.chem-div-2' },
          { name: 'Épaulettes (x2) : ajout / remplacement / retrait', type: 'SIMPLE', price: 16, id: '2.chem-div-3' },
          { name: 'Manches (x2) : ajout / remplacement', type: 'SIMPLE', price: 25, id: '2.chem-div-4' },
          { name: 'Zip : ajout / remplacement / retrait', type: 'SIMPLE', price: 15, id: '2.chem-div-5', multiple: true },
          { name: 'Zip invisible', type: 'SIMPLE', price: 22, id: '2.chem-div-6', multiple: true },
          { name: 'Poche (x1) : ajout / remplacement', type: 'SIMPLE', price: 12, id: '2.chem-div-7', multiple: true },
          { name: 'Col : ajout / remplacement', type: 'SIMPLE', price: 41, id: '2.chem-div-8' },
          { name: 'Boutonnière (petite) : création', type: 'SIMPLE', price: 40, id: '2.chem-div-9', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE', price: 1, id: '2.chem-div-10', multiple: true },
          { name: 'Déchirure : réparation', type: 'SIMPLE', price: 6, id: '2.chem-div-11', multiple: true },
          { name: 'Passant : ajout / remplacement', type: 'SIMPLE', price: 7, id: '2.chem-div-12', multiple: true },
          { name: 'Couture d\'appoint ou décousue - simple', type: 'SIMPLE', price: 4, id: '2.chem-div-13', multiple: true },
          { name: 'Couture d\'appoint ou décousue - complexe', type: 'SIMPLE', price: 10, id: '2.chem-div-14', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'SIMPLE', price: 22, id: '2.chem-div-15', multiple: true },

          { name: 'Poignets (x2) : remplacement / changement', type: 'COMPLEX', price: 23, id: '2.chem-div-16' },
          { name: 'Coudières (x2) : ajout / remplacement', type: 'COMPLEX', price: 20, id: '2.chem-div-17' },
          { name: 'Épaulettes (x2) : ajout / remplacement / retrait', type: 'COMPLEX', price: 20, id: '2.chem-div-18' },
          { name: 'Manches (x2) : ajout / remplacement', type: 'COMPLEX', price: 41, id: '2.chem-div-19' },
          { name: 'Zip : ajout / remplacement / retrait', type: 'COMPLEX', price: 15, id: '2.chem-div-20', multiple: true },
          { name: 'Zip invisible', type: 'COMPLEX', price: 29, id: '2.chem-div-21', multiple: true },
          { name: 'Poche (x1) : ajout / remplacement', type: 'COMPLEX', price: 15, id: '2.chem-div-22', multiple: true },
          { name: 'Col : ajout / remplacement', type: 'COMPLEX', price: 50, id: '2.chem-div-23' },
          { name: 'Boutonnière (petite) : création', type: 'COMPLEX', price: 57, id: '2.chem-div-24', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'COMPLEX', price: 2, id: '2.chem-div-25', multiple: true },
          { name: 'Déchirure : réparation', type: 'COMPLEX', price: 8, id: '2.chem-div-26', multiple: true },
          { name: 'Passant : ajout / remplacement', type: 'COMPLEX', price: 10, id: '2.chem-div-27', multiple: true },
          { name: 'Couture d\'appoint ou décousue - simple', type: 'COMPLEX', price: 4, id: '2.chem-div-28', multiple: true },
          { name: 'Couture d\'appoint ou décousue - complexe', type: 'COMPLEX', price: 10, id: '2.chem-div-29', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'COMPLEX', price: 23, id: '2.chem-div-30', multiple: true },
        ],
      },
    ],
  },
  {
    name: 'Pantalon',
    slug: 'pant',
    types: [
      { slug: 'SIMPLE', name: 'basique' },
      { slug: 'COMPLEX', name: 'tailleur' },
    ],
    categories: [
      {
        name: 'Cintrer',
        items: [
          { name: 'Ceinture', type: 'SIMPLE', price: 41, id: '2.pant-cint-1' },
          { name: 'Jambes (x2)', type: 'SIMPLE', price: 50, id: '2.pant-cint-2' },
          { name: 'Bas de jambes (x2)', type: 'SIMPLE', price: 29, id: '2.pant-cint-3' },
          { name: 'Entrejambe', type: 'SIMPLE', price: 29, id: '2.pant-cint-4' },

          { name: 'Ceinture', type: 'COMPLEX', price: 45, liningUnitPrice: 11, id: '2.pant-cint-5' },
          { name: 'Jambes (x2)', type: 'COMPLEX', price: 55, liningUnitPrice: 9, id: '2.pant-cint-6' },
          { name: 'Bas de jambes (x2)', type: 'COMPLEX', price: 34, liningUnitPrice: 8, id: '2.pant-cint-7' },
          { name: 'Entrejambe', type: 'COMPLEX', price: 34, liningUnitPrice: 9, id: '2.pant-cint-8' },
        ],
      },
      {
        name: 'Élargir',
        items: [
          { name: 'Ceinture', type: 'SIMPLE', price: 50, id: '2.pant-ela-1' },
          { name: 'Jambes (x2)', type: 'SIMPLE', price: 55, id: '2.pant-ela-2' },
          { name: 'Bas de jambes (x2)', type: 'SIMPLE', price: 33, id: '2.pant-ela-3' },
          { name: 'Entrejambe', type: 'SIMPLE', price: 33, id: '2.pant-ela-4' },

          { name: 'Ceinture', type: 'COMPLEX', price: 50, liningUnitPrice: 12, id: '2.pant-ela-5' },
          { name: 'Jambes (x2)', type: 'COMPLEX', price: 58, liningUnitPrice: 10, id: '2.pant-ela-6' },
          { name: 'Bas de jambes (x2)', type: 'COMPLEX', price: 37, liningUnitPrice: 9, id: '2.pant-ela-7' },
          { name: 'Entrejambe', type: 'COMPLEX', price: 37, liningUnitPrice: 10, id: '2.pant-ela-8' },
        ],
      },
      {
        name: 'Raccourcir',
        items: [
          { name: 'Ourlet simple (x2)', type: 'SIMPLE', price: 12, id: '2.pant-rac-1' },
          { name: 'Ourlet revers (x2)', type: 'SIMPLE', price: 17, id: '2.pant-rac-2' },

          { name: 'Ourlet simple (x2)', type: 'COMPLEX', price: 17, liningUnitPrice: 7, id: '2.pant-rac-3' },
          { name: 'Ourlet revers (x2)', type: 'COMPLEX', price: 20, liningUnitPrice: 9, id: '2.pant-rac-4' },
        ],
      },
      {
        name: 'Rallonger',
        items: [
          { name: 'Jambes (x2)', type: 'SIMPLE', price: 17, id: '2.pant-ral-1' },
          { name: 'Ourlet (x2) : suppression des revers', type: 'SIMPLE', price: 12, id: '2.pant-ral-2' },

          { name: 'Jambes (x2)', type: 'COMPLEX', price: 20, liningUnitPrice: 9, id: '2.pant-ral-3' },
          { name: 'Jambes à volants (x2)', type: 'COMPLEX', price: 23, liningUnitPrice: 10, id: '2.pant-ral-4' },
          { name: 'Ourlet (x2) : suppression des revers', type: 'COMPLEX', price: 17, liningUnitPrice: 7, id: '2.pant-ral-5' },
        ],
      },
      {
        name: 'Broderie main',
        items: [
          { name: 'Initiales', type: 'SIMPLE', price: 15, id: '2.pant-bro-1', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'SIMPLE', price: 22, id: '2.pant-bro-2', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'SIMPLE', price: 45, id: '2.pant-bro-3', multiple: true },
          { name: 'Initiales', type: 'COMPLEX', price: 15, id: '2.pant-bro-4', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'COMPLEX', price: 22, id: '2.pant-bro-5', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'COMPLEX', price: 45, id: '2.pant-bro-6', multiple: true },
        ],
      },
      {
        name: 'Divers',
        items: [
          { name: 'Zip : ajout / remplacement / retrait', type: 'SIMPLE', price: 17, id: '2.pant-div-1', multiple: true },
          { name: 'Zip invisible', type: 'SIMPLE', price: 29, id: '2.pant-div-2', multiple: true },
          { name: 'Ceinture : ajout / remplacement', type: 'SIMPLE', price: 41, id: '2.pant-div-3' },
          { name: 'Genouillères (x2) : ajout / remplacement', type: 'SIMPLE', price: 17, id: '2.pant-div-4' },
          { name: 'Poche : changement doublure', type: 'SIMPLE', price: 20, id: '2.pant-div-5', multiple: true },
          { name: 'Poche : ajout / remplacement', type: 'SIMPLE', price: 25, id: '2.pant-div-6', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE', price: 3, id: '2.pant-div-7', multiple: true },
          { name: 'Déchirure : réparation', type: 'SIMPLE', price: 10, id: '2.pant-div-8', multiple: true },
          { name: 'Passant : ajout / remplacement', type: 'SIMPLE', price: 7, id: '2.pant-div-9', multiple: true },
          { name: 'Pince (x1) : ajout', type: 'SIMPLE', price: 20, id: '2.pant-div-10', multiple: true },
          { name: 'Couture d\'appoint ou décousue - simple', type: 'SIMPLE', price: 4, id: '2.pant-div-11', multiple: true },
          { name: 'Couture d\'appoint ou décousue - complexe', type: 'SIMPLE', price: 10, id: '2.pant-div-12', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'SIMPLE', price: 22, id: '2.pant-div-13', multiple: true },

          { name: 'Zip : ajout / remplacement / retrait', type: 'COMPLEX', price: 19, liningUnitPrice: 7, id: '2.pant-div-14', multiple: true },
          { name: 'Zip invisible', type: 'COMPLEX', price: 32, liningUnitPrice: 12, id: '2.pant-div-15', multiple: true },
          { name: 'Ceinture : ajout / remplacement', type: 'COMPLEX', price: 41, id: '2.pant-div-16' },
          { name: 'Genouillères (x2) : ajout / remplacement', type: 'COMPLEX', price: 17, id: '2.pant-div-17' },
          { name: 'Poche : changement doublure', type: 'COMPLEX', price: 20, id: '2.pant-div-18', multiple: true },
          { name: 'Poche : ajout / remplacement', type: 'COMPLEX', price: 25, id: '2.pant-div-19', multiple: true },
          { name: 'Boutonnière (petite) : création', type: 'COMPLEX', price: 57, liningUnitPrice: 15, id: '2.pant-div-20', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'COMPLEX', price: 3, id: '2.pant-div-21', multiple: true },
          { name: 'Déchirure : réparation', type: 'COMPLEX', price: 10, id: '2.pant-div-22', multiple: true },
          { name: 'Passant : ajout / remplacement', type: 'COMPLEX', price: 10, liningUnitPrice: 2, id: '2.pant-div-23', multiple: true },
          { name: 'Pince (x1) : ajout', type: 'COMPLEX', price: 20, liningUnitPrice: 4, id: '2.pant-div-24', multiple: true },
          { name: 'Couture d\'appoint ou décousue - simple', type: 'COMPLEX', price: 4, liningUnitPrice: 1, id: '2.pant-div-25', multiple: true },
          { name: 'Couture d\'appoint ou décousue - complexe', type: 'COMPLEX', price: 10, liningUnitPrice: 2, id: '2.pant-div-26', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'COMPLEX', price: 22, liningUnitPrice: 4, id: '2.pant-div-27', multiple: true },
        ],
      },
    ],
  },
  {
    name: 'Jupe',
    slug: 'jupe',
    types: [
      { slug: 'SIMPLE', name: 'simple' },
      { slug: 'COMPLEX', name: 'tailleur & soirée' },
    ],
    categories: [
      {
        name: 'Cintrer',
        items: [
          { name: 'Ceinture', type: 'SIMPLE', price: 27, id: '2.jupe-cin-1' },
          { name: 'Côtés (x2)', type: 'SIMPLE', price: 27, liningUnitPrice: 4, id: '2.jupe-cin-2' },

          { name: 'Ceinture', type: 'COMPLEX', price: 41, liningUnitPrice: 11, id: '2.jupe-cin-3' },
          { name: 'Côtés (x2)', type: 'COMPLEX', price: 41, liningUnitPrice: 9, id: '2.jupe-cin-4' },
        ],
      },
      {
        name: 'Élargir',
        items: [
          { name: 'Ceinture', type: 'SIMPLE', price: 29, id: '2.jupe-ela-1' },
          { name: 'Côtés (x2)', type: 'SIMPLE', price: 29, id: '2.jupe-ela-2' },

          { name: 'Ceinture', type: 'COMPLEX', price: 29, liningUnitPrice: 29, id: '2.jupe-ela-3' },
          { name: 'Côtés (x2)', type: 'COMPLEX', price: 41, liningUnitPrice: 29, id: '2.jupe-ela-4' },
        ],
      },
      {
        name: 'Raccourcir',
        items: [
          { name: 'Ourlet simple', type: 'SIMPLE', price: 18, liningUnitPrice: 5, id: '2.jupe-rac-1' },
          { name: 'Ourlet à volants', type: 'SIMPLE', price: 29, liningUnitPrice: 8, id: '2.jupe-rac-2' },
          { name: 'Ourlet revers', type: 'SIMPLE', price: 22, liningUnitPrice: 6, id: '2.jupe-rac-3' },

          { name: 'Ourlet revers', type: 'COMPLEX', price: 38, liningUnitPrice: 9, id: '2.jupe-rac-4' },
          { name: 'Ourlet simple', type: 'COMPLEX', price: 36, liningUnitPrice: 8, id: '2.jupe-rac-5' },
          { name: 'Ourlet à volants', type: 'COMPLEX', price: 46, liningUnitPrice: 10, id: '2.jupe-rac-6' },
        ],
      },
      {
        name: 'Rallonger',
        items: [
          { name: 'Longueur simple', type: 'SIMPLE', price: 18, id: '2.jupe-ral-1' },
          { name: 'Longueur à volants', type: 'SIMPLE', price: 29, id: '2.jupe-ral-2' },
          { name: 'Ourlet (x2) : suppression des revers', type: 'SIMPLE', price: 17, id: '2.jupe-ral-3' },

          { name: 'Longueur simple', type: 'COMPLEX', price: 23, liningUnitPrice: 7, id: '2.jupe-ral-4' },
          { name: 'Longueur à volants', type: 'COMPLEX', price: 32, liningUnitPrice: 6, id: '2.jupe-ral-5' },
          { name: 'Ourlet (x2) : suppression des revers', type: 'COMPLEX', price: 19, liningUnitPrice: 4, id: '2.jupe-ral-6' },
        ],
      },
      {
        name: 'Broderie main',
        items: [
          { name: 'Initiales', type: 'SIMPLE', price: 15, id: '2.jupe-bro-1', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'SIMPLE', price: 22, id: '2.jupe-bro-2', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'SIMPLE', price: 45, id: '2.jupe-bro-3', multiple: true },

          { name: 'Initiales', type: 'COMPLEX', price: 15, id: '2.jupe-bro-4', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'COMPLEX', price: 22, id: '2.jupe-bro-5', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'COMPLEX', price: 45, id: '2.jupe-bro-6', multiple: true },
        ],
      },
      {
        name: 'Divers',
        items: [
          { name: 'Doublure : ajout / remplacement / retrait', type: 'SIMPLE', price: 50, id: '2.jupe-div-1' },
          { name: 'Zip : ajout / remplacement / retrait', type: 'SIMPLE', price: 15, liningUnitPrice: 5, id: '2.jupe-div-2', multiple: true },
          { name: 'Zip invisible', type: 'SIMPLE', price: 27, liningUnitPrice: 7, id: '2.jupe-div-3', multiple: true },
          { name: 'Ceinture : ajout / remplacement', type: 'SIMPLE', price: 37, id: '2.jupe-div-4', multiple: true },
          { name: 'Fente : ajout / remplacement / retrait', type: 'SIMPLE', price: 23, liningUnitPrice: 5, id: '2.jupe-div-5', multiple: true },
          { name: 'Pince (x1) : ajout', type: 'SIMPLE', price: 20, id: '2.jupe-div-6', multiple: true },
          { name: 'Poche (x1) : changement de doublure', type: 'SIMPLE', price: 20, id: '2.jupe-div-7', multiple: true },
          { name: 'Poche (x1) : ajout / remplacement', type: 'SIMPLE', price: 25, id: '2.jupe-div-8', multiple: true },
          { name: 'Boutonnière (petite) : création', type: 'SIMPLE', price: 40, id: '2.jupe-div-9', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE', price: 3, id: '2.jupe-div-10', multiple: true },
          { name: 'Déchirure : réparation', type: 'SIMPLE', price: 8, id: '2.jupe-div-11', multiple: true },
          { name: 'Passant : ajout / remplacement', type: 'SIMPLE', price: 7, id: '2.jupe-div-12', multiple: true },
          { name: 'Couture d\'appoint ou décousue - simple', type: 'SIMPLE', price: 4, id: '2.jupe-div-13', multiple: true },
          { name: 'Couture d\'appoint ou décousue - complexe', type: 'SIMPLE', price: 10, id: '2.jupe-div-14', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'SIMPLE', price: 22, id: '2.jupe-div-15', multiple: true },

          { name: 'Doublure : ajout / remplacement / retrait', type: 'COMPLEX', price: 50, id: '2.jupe-div-16' },
          { name: 'Zip : ajout / remplacement / retrait', type: 'COMPLEX', price: 19, liningUnitPrice: 7, id: '2.jupe-div-17', multiple: true },
          { name: 'Zip invisible', type: 'COMPLEX', price: 32, liningUnitPrice: 12, id: '2.jupe-div-18', multiple: true },
          { name: 'Ceinture : ajout / remplacement', type: 'COMPLEX', price: 41, id: '2.jupe-div-19', multiple: true },
          { name: 'Fente : ajout / remplacement / retrait', type: 'COMPLEX', price: 23, liningUnitPrice: 23, id: '2.jupe-div-20', multiple: true },
          { name: 'Pince (x1) : ajout', type: 'COMPLEX', price: 20, liningUnitPrice: 4, id: '2.jupe-div-21', multiple: true },
          { name: 'Poche (x1) : changement de doublure', type: 'COMPLEX', price: 20, liningUnitPrice: 20, id: '2.jupe-div-22', multiple: true },
          { name: 'Poche (x1) : ajout / remplacement', type: 'COMPLEX', price: 25, id: '2.jupe-div-23', multiple: true },
          { name: 'Boutonnière (petite) : création', type: 'COMPLEX', price: 48, liningUnitPrice: 12, id: '2.jupe-div-24', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'COMPLEX', price: 3, id: '2.jupe-div-25', multiple: true },
          { name: 'Déchirure : réparation', type: 'COMPLEX', price: 10, id: '2.jupe-div-26', multiple: true },
          { name: 'Passant : ajout / remplacement', type: 'COMPLEX', price: 10, liningUnitPrice: 7, id: '2.jupe-div-27', multiple: true },
          { name: 'Couture d\'appoint ou décousue - simple', type: 'COMPLEX', price: 4, liningUnitPrice: 1, id: '2.jupe-div-28', multiple: true },
          { name: 'Couture d\'appoint ou décousue - complexe', type: 'COMPLEX', price: 10, liningUnitPrice: 2, id: '2.jupe-div-29', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'COMPLEX', price: 23, liningUnitPrice: 4, id: '2.jupe-div-30', multiple: true },
        ],
      },
    ],
  },
  {
    name: 'Robe',
    slug: 'robe',
    types: [
      { slug: 'SIMPLE', name: 'simple' },
      { slug: 'COMPLEX', name: 'soirée & mariage' },
    ],
    categories: [
      {
        name: 'Cintrer',
        items: [
          { name: 'Côtés (x2)', type: 'SIMPLE', price: 29, liningUnitPrice: 12, id: '2.robe-cin-1' },
          { name: 'Épaules (x2)', type: 'SIMPLE', price: 20, liningUnitPrice: 3, id: '2.robe-cin-2' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'SIMPLE', price: 29, id: '2.robe-cin-3' },
          { name: 'Manches (x2 - boutonnière)', type: 'SIMPLE', price: 50, id: '2.robe-cin-4' },
          { name: 'Bustier / taille', type: 'SIMPLE', price: 29, liningUnitPrice: 4, id: '2.robe-cin-5' },
          { name: 'Dos', type: 'SIMPLE', price: 29, liningUnitPrice: 4, id: '2.robe-cin-6' },

          { name: 'Côtés (x2)', type: 'COMPLEX', price: 63, liningUnitPrice: 28, id: '2.robe-cin-7' },
          { name: 'Épaules (x2)', type: 'COMPLEX', price: 42, liningUnitPrice: 8, id: '2.robe-cin-8' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'COMPLEX', price: 49, id: '2.robe-cin-9' },
          { name: 'Manches (x2 - boutonnière)', type: 'COMPLEX', price: 63, id: '2.robe-cin-10' },
          { name: 'Bustier / taille', type: 'COMPLEX', price: 63, liningUnitPrice: 8, id: '2.robe-cin-11' },
          { name: 'Dos', type: 'COMPLEX', price: 63, liningUnitPrice: 8, id: '2.robe-cin-12' },
        ],
      },
      {
        name: 'élargir',
        items: [
          { name: 'Côtés (x2)', type: 'SIMPLE', price: 30, liningUnitPrice: 12, id: '2.robe-ela-1' },
          { name: 'Épaules (x2)', type: 'SIMPLE', price: 22, liningUnitPrice: 4, id: '2.robe-ela-2' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'SIMPLE', price: 29, id: '2.robe-ela-3' },
          { name: 'Manches (x2 - boutonnière)', type: 'SIMPLE', price: 50, id: '2.robe-ela-4' },
          { name: 'Bustier / taille', type: 'SIMPLE', price: 30, liningUnitPrice: 5, id: '2.robe-ela-5' },
          { name: 'Dos', type: 'SIMPLE', price: 30, liningUnitPrice: 5, id: '2.robe-ela-6' },

          { name: 'Côtés (x2)', type: 'COMPLEX', price: 35, liningUnitPrice: 12, id: '2.robe-ela-7' },
          { name: 'Épaules (x2)', type: 'COMPLEX', price: 27, liningUnitPrice: 7, id: '2.robe-ela-8' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'COMPLEX', price: 49, id: '2.robe-ela-9' },
          { name: 'Manches (x2 - boutonnière)', type: 'COMPLEX', price: 63, id: '2.robe-ela-10' },
          { name: 'Bustier / taille', type: 'COMPLEX', price: 63, liningUnitPrice: 8, id: '2.robe-ela-11' },
          { name: 'Dos', type: 'COMPLEX', price: 63, liningUnitPrice: 8, id: '2.robe-ela-12' },
        ],
      },
      {
        name: 'Raccourcir',
        items: [
          { name: 'Ourlet simple', type: 'SIMPLE', price: 18, liningUnitPrice: 5, id: '2.robe-rac-1' },
          { name: 'Ourlet à volants', type: 'SIMPLE', price: 29, liningUnitPrice: 8, id: '2.robe-rac-2' },
          { name: 'Ourlet revers', type: 'SIMPLE', price: 22, liningUnitPrice: 6, id: '2.robe-rac-3' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'SIMPLE', price: 10, id: '2.robe-rac-4' },
          { name: 'Manches (x2 - boutonnière)', type: 'SIMPLE', price: 30, id: '2.robe-rac-5' },
          { name: 'Bretelles (x2)', type: 'SIMPLE', price: 15, id: '2.robe-rac-6' },

          { name: 'Ourlet simple', type: 'COMPLEX', price: 63, liningUnitPrice: 13, id: '2.robe-rac-7' },
          { name: 'Ourlet à volants', type: 'COMPLEX', price: 84, liningUnitPrice: 15, id: '2.robe-rac-8' },
          { name: 'Ourlet revers', type: 'COMPLEX', price: 65, liningUnitPrice: 13, id: '2.robe-rac-9' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'COMPLEX', price: 12, id: '2.robe-rac-10' },
          { name: 'Manches (x2 - boutonnière)', type: 'COMPLEX', price: 32, id: '2.robe-rac-11' },
          { name: 'Bretelles (x2)', type: 'COMPLEX', price: 28, id: '2.robe-rac-12' },
        ],
      },
      {
        name: 'Rallonger',
        items: [
          { name: 'Longueur simple', type: 'SIMPLE', price: 18, liningUnitPrice: 5, id: '2.robe-ral-1' },
          { name: 'Longueur à volants', type: 'SIMPLE', price: 29, liningUnitPrice: 8, id: '2.robe-ral-2' },
          { name: 'Ourlets (x2) : suppression des revers', type: 'SIMPLE', price: 17, id: '2.robe-ral-3' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'SIMPLE', price: 10, id: '2.robe-ral-4' },
          { name: 'Manches (x2 - boutonnière)', type: 'SIMPLE', price: 30, id: '2.robe-ral-5' },
          { name: 'Bretelles (x2)', type: 'SIMPLE', price: 15, liningUnitPrice: 4, id: '2.robe-ral-6' },

          { name: 'Longueur simple', type: 'COMPLEX', price: 63, liningUnitPrice: 13, id: '2.robe-ral-7' },
          { name: 'Longueur à volants', type: 'COMPLEX', price: 84, liningUnitPrice: 15, id: '2.robe-ral-8' },
          { name: 'Ourlets (x2) : suppression des revers', type: 'COMPLEX', price: 59, liningUnitPrice: 11, id: '2.robe-ral-9' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'COMPLEX', price: 12, id: '2.robe-ral-10' },
          { name: 'Manches (x2 - boutonnière)', type: 'COMPLEX', price: 32, id: '2.robe-ral-11' },
          { name: 'Bretelles (x2)', type: 'COMPLEX', price: 28, id: '2.robe-ral-12' },
        ],
      },
      {
        name: 'Broderie main',
        items: [
          { name: 'Initiales', type: 'SIMPLE', price: 15, id: '2.robe-bro-1', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'SIMPLE', price: 22, id: '2.robe-bro-2', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'SIMPLE', price: 45, id: '2.robe-bro-3', multiple: true },

          { name: 'Initiales', type: 'COMPLEX', price: 17, id: '2.robe-bro-4', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'COMPLEX', price: 25, id: '2.robe-bro-5', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'COMPLEX', price: 49, id: '2.robe-bro-6', multiple: true },
        ],
      },
      {
        name: 'Divers',
        items: [
          { name: 'Doublure : ajout / remplacement / retrait', type: 'SIMPLE', price: 65, id: '2.robe-div-1' },
          { name: 'Zip : ajout / remplacement / retrait', type: 'SIMPLE', price: 28, liningUnitPrice: 10, id: '2.robe-div-2', multiple: true },
          { name: 'Zip invisible', type: 'SIMPLE', price: 41, liningUnitPrice: 11, id: '2.robe-div-3', multiple: true },
          { name: 'Bretelles (x2) : ajout / remplacement / retrait', type: 'SIMPLE', price: 12, id: '2.robe-div-4' },
          { name: 'Ceinture : ajout / remplacement', type: 'SIMPLE', price: 39, id: '2.robe-div-5', multiple: true },
          { name: 'Fente : ajout / remplacement / retrait', type: 'SIMPLE', price: 25, liningUnitPrice: 5, id: '2.robe-div-6', multiple: true },
          { name: 'Pince (x1) : ajout', type: 'SIMPLE', price: 20, id: '2.robe-div-7', multiple: true },
          { name: 'Poche (x1) : changement de doublure', type: 'SIMPLE', price: 20, id: '2.robe-div-8', multiple: true },
          { name: 'Poche (x1) : ajout / remplacement', type: 'SIMPLE', price: 25, id: '2.robe-div-9', multiple: true },
          { name: 'Boutonnière (petite) : création', type: 'SIMPLE', price: 40, id: '2.robe-div-10', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE', price: 3, id: '2.robe-div-11', multiple: true },
          { name: 'Déchirure : réparation', type: 'SIMPLE', price: 8, id: '2.robe-div-12', multiple: true },
          { name: 'Passant : ajout / remplacement', type: 'SIMPLE', price: 7, id: '2.robe-div-13', multiple: true },
          { name: 'Couture d\'appoint ou décousue - simple', type: 'SIMPLE', price: 4, id: '2.robe-div-14', multiple: true },
          { name: 'Couture d\'appoint ou décousue - complexe', type: 'SIMPLE', price: 10, id: '2.robe-div-15', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'SIMPLE', price: 22, id: '2.robe-div-16', multiple: true },

          { name: 'Doublure : ajout / remplacement / retrait', type: 'COMPLEX', price: 69, id: '2.robe-div-17' },
          { name: 'Zip : ajout / remplacement / retrait', type: 'COMPLEX', price: 49, liningUnitPrice: 13, id: '2.robe-div-18', multiple: true },
          { name: 'Zip invisible', type: 'COMPLEX', price: 63, liningUnitPrice: 15, id: '2.robe-div-19', multiple: true },
          { name: 'Bretelles (x2) : ajout / remplacement / retrait', type: 'COMPLEX', price: 31, id: '2.robe-div-20' },
          { name: 'Ceinture : ajout / remplacement', type: 'COMPLEX', price: 42, id: '2.robe-div-21', multiple: true },
          { name: 'Fente : ajout / remplacement / retrait', type: 'COMPLEX', price: 29, liningUnitPrice: 6, id: '2.robe-div-22', multiple: true },
          { name: 'Pince (x1) : ajout', type: 'COMPLEX', price: 25, liningUnitPrice: 25, id: '2.robe-div-23', multiple: true },
          { name: 'Poche (x1) : changement de doublure', type: 'COMPLEX', price: 20, id: '2.robe-div-24', multiple: true },
          { name: 'Poche (x1) : ajout / remplacement', type: 'COMPLEX', price: 25, id: '2.robe-div-25', multiple: true },
          { name: 'Boutonnière (petite) : création', type: 'COMPLEX', price: 49, id: '2.robe-div-26', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'COMPLEX', price: 4, id: '2.robe-div-27', multiple: true },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'COMPLEX', price: 7, id: '2.robe-div-28', multiple: true },
          { name: 'Déchirure : réparation', type: 'COMPLEX', price: 17, id: '2.robe-div-29', multiple: true },
          { name: 'Passant : ajout / remplacement', type: 'COMPLEX', price: 14, id: '2.robe-div-30', multiple: true },
          { name: 'Couture d\'appoint ou décousue - simple', type: 'COMPLEX', price: 11, id: '2.robe-div-31', multiple: true },
          { name: 'Couture d\'appoint ou décousue - complexe', type: 'COMPLEX', price: 20, id: '2.robe-div-32', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'COMPLEX', price: 35, id: '2.robe-div-33', multiple: true },
        ],
      },
    ],
  },
  {
    name: 'Pull & gillet',
    slug: 'pull',
    types: [
      { slug: 'SIMPLE', name: 'basique' },
      { slug: 'COMPLEX', name: 'laine & cachemire' },
    ],
    categories: [
      {
        name: 'Cintrer',
        items: [
          { name: 'Côtés (x2)', type: 'SIMPLE', price: 16, id: '2.pull-cin-1' },
          { name: 'Épaules (x2)', type: 'SIMPLE', price: 18, id: '2.pull-cin-2' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'SIMPLE', price: 21, id: '2.pull-cin-3' },
          { name: 'Taille', type: 'SIMPLE', price: 18, id: '2.pull-cin-4' },
          { name: 'Dos', type: 'SIMPLE', price: 11, id: '2.pull-cin-5' },

          { name: 'Côtés (x2)', type: 'COMPLEX', price: 40, id: '2.pull-cin-6' },
          { name: 'Épaules (x2)', type: 'COMPLEX', price: 45, id: '2.pull-cin-7' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'COMPLEX', price: 52, id: '2.pull-cin-8' },
          { name: 'Taille', type: 'COMPLEX', price: 45, id: '2.pull-cin-9' },
          { name: 'Dos', type: 'COMPLEX', price: 27, id: '2.pull-cin-10' },
        ],
      },
      {
        name: 'Élargir',
        items: [
          { name: 'Côtés (x2)', type: 'SIMPLE', price: 17, id: '2.pull-ela-1' },
          { name: 'Épaules (x2)', type: 'SIMPLE', price: 19, id: '2.pull-ela-2' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'SIMPLE', price: 24, id: '2.pull-ela-3' },
          { name: 'Taille', type: 'SIMPLE', price: 20, id: '2.pull-ela-4' },
          { name: 'Dos', type: 'SIMPLE', price: 13, id: '2.pull-ela-5' },

          { name: 'Côtés (x2)', type: 'COMPLEX', price: 42, id: '2.pull-ela-6' },
          { name: 'Épaules (x2)', type: 'COMPLEX', price: 47, id: '2.pull-ela-7' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'COMPLEX', price: 60, id: '2.pull-ela-8' },
          { name: 'Taille', type: 'COMPLEX', price: 50, id: '2.pull-ela-9' },
          { name: 'Dos', type: 'COMPLEX', price: 32, id: '2.pull-ela-10' },
        ],
      },
      {
        name: 'Raccourcir',
        items: [
          { name: 'Longueur', type: 'SIMPLE', price: 14, id: '2.pull-rac-1' },
          { name: 'Manches (x2 - par le bas)', type: 'SIMPLE', price: 12, id: '2.pull-rac-2' },
          { name: 'Manches (x2 - par l\'épaule)', type: 'SIMPLE', price: 37, id: '2.pull-rac-3' },

          { name: 'Longueur', type: 'COMPLEX', price: 35, id: '2.pull-rac-4' },
          { name: 'Manches (x2 - par le bas)', type: 'COMPLEX', price: 30, id: '2.pull-rac-5' },
          { name: 'Manches (x2 - par l\'épaule)', type: 'COMPLEX', price: 92, id: '2.pull-rac-6' },
        ],
      },
      {
        name: 'Rallonger',
        items: [
          { name: 'Longueur', type: 'SIMPLE', price: 17, id: '2.pull-ral-1' },
          { name: 'Manches (x2 - par le bas)', type: 'SIMPLE', price: 12, id: '2.pull-ral-2' },
          { name: 'Manches (x2 - par l\'épaule)', type: 'SIMPLE', price: 37, id: '2.pull-ral-3' },

          { name: 'Longueur', type: 'COMPLEX', price: 42, id: '2.pull-ral-4' },
          { name: 'Manches (x2 - par le bas)', type: 'COMPLEX', price: 30, id: '2.pull-ral-5' },
          { name: 'Manches (x2 - par l\'épaule)', type: 'COMPLEX', price: 92, id: '2.pull-ral-6' },
        ],
      },
      {
        name: 'Broderie main',
        items: [
          { name: 'Initiales', type: 'SIMPLE', price: 15, id: '2.pull-bro-1', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'SIMPLE', price: 22, id: '2.pull-bro-2', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'SIMPLE', price: 45, id: '2.pull-bro-3', multiple: true },

          { name: 'Initiales', type: 'COMPLEX', price: 37, id: '2.pull-bro-4', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'COMPLEX', price: 55, id: '2.pull-bro-5', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'COMPLEX', price: 112, id: '2.pull-bro-6', multiple: true },
        ],
      },
      {
        name: 'Divers',
        items: [
          { name: 'Poignets (x2) : remplacement', type: 'SIMPLE', price: 14, id: '2.pull-div-1' },
          { name: 'Coudières (x2) : ajout / remplacement', type: 'SIMPLE', price: 18, id: '2.pull-div-2' },
          { name: 'Épaulettes (x2) : ajout / remplacement / retrait', type: 'SIMPLE', price: 21, id: '2.pull-div-3' },
          { name: 'Manches (x2) : ajout / remplacement', type: 'SIMPLE', price: 50, id: '2.pull-div-4' },
          { name: 'Zip : ajout / remplacement / retrait', type: 'SIMPLE', price: 16, id: '2.pull-div-5', multiple: true },
          { name: 'Zip invisible', type: 'SIMPLE', price: 23, id: '2.pull-div-6', multiple: true },
          { name: 'Poche (x1) : ajout / remplacement', type: 'SIMPLE', price: 14, id: '2.pull-div-7', multiple: true },
          { name: 'Col : ajout / remplacement', type: 'SIMPLE', price: 45, id: '2.pull-div-8', multiple: true },
          { name: 'Boutonnière (petite) : création', type: 'SIMPLE', price: 47, id: '2.pull-div-9', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE', price: 2, id: '2.pull-div-10', multiple: true },
          { name: 'Déchirure : réparation', type: 'SIMPLE', price: 8, id: '2.pull-div-11', multiple: true },
          { name: 'Passant : ajout / remplacement', type: 'SIMPLE', price: 8, id: '2.pull-div-12', multiple: true },
          { name: 'Couture d\'appoint ou décousue - simple', type: 'SIMPLE', price: 5, id: '2.pull-div-13', multiple: true },
          { name: 'Couture d\'appoint ou décousue - complexe', type: 'SIMPLE', price: 11, id: '2.pull-div-14', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'SIMPLE', price: 23, id: '2.pull-div-15', multiple: true },

          { name: 'Poignets (x2) : remplacement', type: 'COMPLEX', price: 35, id: '2.pull-div-16' },
          { name: 'Coudières (x2) : ajout / remplacement', type: 'COMPLEX', price: 45, id: '2.pull-div-17' },
          { name: 'Épaulettes (x2) : ajout / remplacement / retrait', type: 'COMPLEX', price: 52, id: '2.pull-div-18' },
          { name: 'Manches (x2) : ajout / remplacement', type: 'COMPLEX', price: 125, id: '2.pull-div-19' },
          { name: 'Zip : ajout / remplacement / retrait', type: 'COMPLEX', price: 40, id: '2.pull-div-20', multiple: true },
          { name: 'Zip invisible', type: 'COMPLEX', price: 57, id: '2.pull-div-21', multiple: true },
          { name: 'Poche (x1) : ajout / remplacement', type: 'COMPLEX', price: 35, id: '2.pull-div-22', multiple: true },
          { name: 'Col : ajout / remplacement', type: 'COMPLEX', price: 112, id: '2.pull-div-23', multiple: true },
          { name: 'Boutonnière (petite) : création', type: 'COMPLEX', price: 117, id: '2.pull-div-24', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'COMPLEX', price: 5, id: '2.pull-div-25', multiple: true },
          { name: 'Déchirure : réparation', type: 'COMPLEX', price: 20, id: '2.pull-div-26', multiple: true },
          { name: 'Passant : ajout / remplacement', type: 'COMPLEX', price: 20, id: '2.pull-div-27', multiple: true },
          { name: 'Couture d\'appoint ou décousue - simple', type: 'COMPLEX', price: 12, id: '2.pull-div-28', multiple: true },
          { name: 'Couture d\'appoint ou décousue - complexe', type: 'COMPLEX', price: 27, id: '2.pull-div-29', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'COMPLEX', price: 57, id: '2.pull-div-30', multiple: true },
        ],
      },
    ],
  },
  {
    name: 'Lingerie & maillot',
    slug: 'ling',
    types: [
      { slug: 'SIMPLE', name: 'basique' },
      { slug: 'COMPLEX', name: 'lingerie fine' },
    ],
    categories: [
      {
        name: 'Cintrer',
        items: [
          { name: 'Tour de poitrine', type: 'SIMPLE', price: 9, id: '2.ling-cin-1' },
          { name: 'Bonnets (x2)', type: 'SIMPLE', price: 12, liningUnitPrice: 3, id: '2.ling-cin-2' },
          { name: 'Bonnets avec armature (x2)', type: 'SIMPLE', price: 24, liningUnitPrice: 5, id: '2.ling-cin-3' },
          { name: 'Tour de taille', type: 'SIMPLE', price: 20, id: '2.ling-cin-4' },
          { name: 'Entrejambe / fessiers', type: 'SIMPLE', price: 24, liningUnitPrice: 2, id: '2.ling-cin-5' },
          { name: 'Tour de cuisse (x2)', type: 'SIMPLE', price: 30, id: '2.ling-cin-6' },

          { name: 'Tour de poitrine', type: 'COMPLEX', price: 22, id: '2.ling-cin-7' },
          { name: 'Bonnets (x2)', type: 'COMPLEX', price: 30, liningUnitPrice: 3, id: '2.ling-cin-8' },
          { name: 'Bonnets avec armature (x2)', type: 'COMPLEX', price: 60, liningUnitPrice: 5, id: '2.ling-cin-9' },
          { name: 'Tour de taille', type: 'COMPLEX', price: 50, id: '2.ling-cin-10' },
          { name: 'Entrejambe / fessiers', type: 'COMPLEX', price: 60, liningUnitPrice: 2, id: '2.ling-cin-11' },
          { name: 'Tour de cuisse (x2)', type: 'COMPLEX', price: 75, id: '2.ling-cin-12' },
        ],
      },
      {
        name: 'Élargir',
        items: [
          { name: 'Tour de poitrine', type: 'SIMPLE', price: 10, id: '2.ling-ela-1' },
          { name: 'Tour de taille', type: 'SIMPLE', price: 20, id: '2.ling-ela-2' },
          { name: 'Entrejambe / fessiers', type: 'SIMPLE', price: 24, liningUnitPrice: 2, id: '2.ling-ela-3' },
          { name: 'Tour de cuisse (x2)', type: 'SIMPLE', price: 30, id: '2.ling-ela-4' },

          { name: 'Tour de poitrine', type: 'COMPLEX', price: 25, id: '2.ling-ela-5' },
          { name: 'Tour de taille', type: 'COMPLEX', price: 50, id: '2.ling-ela-6' },
          { name: 'Entrejambe / fessiers', type: 'COMPLEX', price: 60, liningUnitPrice: 2, id: '2.ling-ela-7' },
          { name: 'Tour de cuisse (x2)', type: 'COMPLEX', price: 75, id: '2.ling-ela-8' },
        ],
      },
      {
        name: 'Raccourcir',
        items: [
          { name: 'Bretelles (x2)', type: 'SIMPLE', price: 8, id: '2.ling-rac-1' },
          { name: 'Forme culotte / caleçon', type: 'SIMPLE', price: 27, liningUnitPrice: 2, id: '2.ling-rac-2' },

          { name: 'Bretelles (x2)', type: 'COMPLEX', price: 20, id: '2.ling-rac-3' },
          { name: 'Forme culotte', type: 'COMPLEX', price: 67, liningUnitPrice: 2, id: '2.ling-rac-4' },
        ],
      },
      {
        name: 'Rallonger',
        items: [
          { name: 'Bretelles (x2)', type: 'SIMPLE', price: 8, id: '2.ling-ral-1' },
          { name: 'Forme culotte / caleçon', type: 'SIMPLE', price: 27, liningUnitPrice: 2, id: '2.ling-ral-2' },

          { name: 'Bretelles (x2)', type: 'COMPLEX', price: 20, id: '2.ling-ral-3' },
          { name: 'Forme culotte', type: 'COMPLEX', price: 67, liningUnitPrice: 2, id: '2.ling-ral-4' },
        ],
      },
      {
        name: 'Broderie main',
        items: [
          { name: 'Initiales', type: 'SIMPLE', price: 15, id: '2.ling-bro-1', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'SIMPLE', price: 22, id: '2.ling-bro-2', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'SIMPLE', price: 45, id: '2.ling-bro-3', multiple: true },

          { name: 'Initiales', type: 'COMPLEX', price: 37, id: '2.ling-bro-4', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'COMPLEX', price: 55, id: '2.ling-bro-5', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'COMPLEX', price: 112, id: '2.ling-bro-6', multiple: true },
        ],
      },
      {
        name: 'Divers',
        items: [
          { name: 'Zip : ajout / remplacement / retrait', type: 'SIMPLE', price: 10, liningUnitPrice: 1, id: '2.ling-div-1', multiple: true },
          { name: 'Zip invisible', type: 'SIMPLE', price: 13, liningUnitPrice: 1, id: '2.ling-div-2', multiple: true },
          { name: 'Bretelles (x2) : ajout / remplacement', type: 'SIMPLE', price: 12, id: '2.ling-div-3' },
          { name: 'Bretelles (x2) : retrait', type: 'SIMPLE', price: 9, id: '2.ling-div-4' },
          { name: 'Boutonnière (petite) : création', type: 'SIMPLE', price: 20, id: '2.ling-div-5', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE', price: 2, id: '2.ling-div-6', multiple: true },
          { name: 'Déchirure : réparation', type: 'SIMPLE', price: 6, liningUnitPrice: 2, id: '2.ling-div-7', multiple: true },
          { name: 'Élastique : ajout / remplacement', type: 'SIMPLE', price: 7, liningUnitPrice: 1, id: '2.ling-div-8', multiple: true },
          { name: 'Attache dos : remplacement', type: 'SIMPLE', price: 10, id: '2.ling-div-9', multiple: true },
          { name: 'Armature (x2) : remplacement', type: 'SIMPLE', price: 8, id: '2.ling-div-10', multiple: true },
          { name: 'Baleines (x2) : remplacement / réparation', type: 'SIMPLE', price: 12, liningUnitPrice: 12, id: '2.ling-div-11', multiple: true },
          { name: 'Couture d\'appoint ou décousue - simple', type: 'SIMPLE', price: 4, liningUnitPrice: 1, id: '2.ling-div-12', multiple: true },
          { name: 'Couture d\'appoint ou décousue - complexe', type: 'SIMPLE', price: 8, liningUnitPrice: 1, id: '2.ling-div-13', multiple: true },

          { name: 'Zip : ajout / remplacement / retrait', type: 'COMPLEX', price: 25, liningUnitPrice: 1, id: '2.ling-div-14', multiple: true },
          { name: 'Zip invisible', type: 'COMPLEX', price: 32, liningUnitPrice: 1, id: '2.ling-div-15', multiple: true },
          { name: 'Bretelles (x2) : ajout / remplacement', type: 'COMPLEX', price: 30, id: '2.ling-div-16' },
          { name: 'Bretelles (x2) : retrait', type: 'COMPLEX', price: 22, id: '2.ling-div-17' },
          { name: 'Boutonnière (petite) : création', type: 'COMPLEX', price: 50, id: '2.ling-div-18', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'COMPLEX', price: 5, id: '2.ling-div-19', multiple: true },
          { name: 'Déchirure : réparation', type: 'COMPLEX', price: 15, liningUnitPrice: 2, id: '2.ling-div-20', multiple: true },
          { name: 'Élastique : ajout / remplacement', type: 'COMPLEX', price: 17, liningUnitPrice: 1, id: '2.ling-div-21', multiple: true },
          { name: 'Attache dos : remplacement', type: 'COMPLEX', price: 25, id: '2.ling-div-22', multiple: true },
          { name: 'Armature (x2) : remplacement', type: 'COMPLEX', price: 20, id: '2.ling-div-23', multiple: true },
          { name: 'Baleines (x2) : remplacement / réparation', type: 'COMPLEX', price: 30, liningUnitPrice: 30, id: '2.ling-div-24', multiple: true },
          { name: 'Couture d\'appoint ou décousue - simple', type: 'COMPLEX', price: 10, liningUnitPrice: 1, id: '2.ling-div-25', multiple: true },
          { name: 'Couture d\'appoint ou décousue - complexe', type: 'COMPLEX', price: 20, liningUnitPrice: 1, id: '2.ling-div-26', multiple: true },
        ],
      },
    ],
  },
  {
    name: 'Décoration',
    slug: 'deco',
    types: [],
    categories: [
      {
        name: 'Rideaux',
        items: [
          { name: 'Ourlet (moins de 2m)', price: 20, liningUnitPrice: 10, id: '2.deco-rid-1', multiple: true },
          { name: 'Ourlet (plus de 2m)', price: 30, liningUnitPrice: 10, id: '2.deco-rid-2', multiple: true },
          { name: 'Couture d\'appoint ou décousue - simple', price: 5, liningUnitPrice: 2, id: '2.deco-rid-3', multiple: true },
          { name: 'Couture d\'appoint ou décousue - complexe', price: 15, liningUnitPrice: 7, id: '2.deco-rid-4', multiple: true },
          { name: 'Déchirure : réparation', price: 20, liningUnitPrice: 10, id: '2.deco-rid-5', multiple: true },
          { name: 'Supplément fil de plomb', price: 10, id: '2.deco-rid-6', multiple: true },
        ],
      },
      {
        name: 'Coussins',
        items: [
          { name: 'Coussin (moins de 45 cm)', price: 14, id: '2.deco-cou-1', multiple: true },
          { name: 'Coussin (plus de 45 cm)', price: 18, id: '2.deco-cou-2', multiple: true },
          { name: 'Zip', price: 15, id: '2.deco-cou-3', multiple: true },
          { name: 'Zip invisible', price: 22, id: '2.deco-cou-4', multiple: true },
        ],
      },
    ],
  },
];

export default pricing;

const pricingInverse = {};
pricing.forEach(
  cloth => cloth.categories.forEach(
    category => category.items.forEach(
      (item) => {
        if (pricingInverse[item.id]) {
          throw new Error(`Inverse pricing, duplicate key : ${item.id}`);
        }
        pricingInverse[item.id] = {
          ...item,
          cloth: cloth.name,
          category: category.name,
        };
      },
    ),
  ),
);

export { pricingInverse, metadata };
