import React from 'react';
import styled from 'styled-components';
import {
  colors, mobileThresholdPixels, Button as ButtonStyled, FormInput as FormInputV3,
} from './Home/v3/styledComponents';
import callApi from '../services/api';
import closeImg from '../assets/menuClose.svg';
import errorLogo from '../assets/error.png';
import verifiedLogo from '../assets/verified.png';

const Background = styled.div`
  @media (max-width: ${mobileThresholdPixels}) {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
  }
`;

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 0px;
  right: 100px;
  width: 390px;
  z-index: 3;
  padding: 10px;

  @media (max-width: ${mobileThresholdPixels}) {
    position: static;
    padding: ${({ noPadding }) => (noPadding ? '0' : '20')}px;
  }
`;

const Container = styled(ButtonContainer)`
  background-color: ${colors.white};
  border-width: thin;
  border-style: solid;
  border-color: ${colors.lightGrey};
  color: ${colors.navy};
`;

const Button = styled(ButtonStyled)`
  min-width: 100px;
  max-width: 200px;
  margin-top: 20px;
  margin-bottom: 0px;
  ${({ isFixed }) => isFixed && `
    position: fixed;
    bottom: 0px;
    right: 100px;
    margin-top: 0px;
    z-index: 6;
  `}

  @media (max-width: ${mobileThresholdPixels}) {
    ${({ isFixed }) => isFixed && `
      right: 0px;
    `}
  }
`;


const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ end }) => (end ? 'flex-end' : 'space-between')};

  ${({ border }) => border && `border-bottom: 0.25px solid ${colors.navy};`}
`;

const Title = styled.h2`
  font-family: Gotham;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: colors.white;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
`;

const Label = styled.p`
  font-family: Roboto;
  font-size: 18px;
  line-height: 28px;
  color: colors.navy;
  margin-bottom: 2px;
  text-align: left;
  margin-block-start: 1em;
`;

const SubLabel = styled.p`
  font-family: Roboto;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.lightGrey4};
  margin-top: 5px;
  margin-bottom: 20px;
  text-align: left;
`;

const FootNote = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.lightGrey};
  color: ${colors.navy};
  font-family: Roboto;
  font-size: 12px;
  text-align: center;
  padding: 10px 0px;
  margin-top: 25px;
  margin-bottom: 30px;
`;

const Img = styled.img``;

const Form = styled.div`
  @media (max-width: ${mobileThresholdPixels}) {
    margin-right: -10px;
  }
`;

const FormInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  color: ${colors.navy};
  width: ${({ small, hasIcon }) => {
    if (!hasIcon) return small ? '47%' : '100%';
    return small ? 'calc(47% + 10px)' : 'calc(100% + 10px)';
  }};
  ${({ hasIcon, right }) => hasIcon && right && 'margin-right: -10px;'}
`;

const LabelFormInput = styled.p`
  font-family: Roboto;
  font-size: 18px;
  margin-bottom: 5px;
  margin-top: 0px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
  }
`;

const FormInput = styled(FormInputV3)`
  margin: 0px 0px 20px;
  border-radius: 2px;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 38px;
  font-family: Roboto;
  font-size: 15px;
  outline: 0;
  border: solid 0.5px ${colors.navy};
  width: ${({ right, hasIcon }) => right && hasIcon ? 'calc(100% + 10px)' : '100%'};
  color: ${colors.navy};
  box-sizing: border-box;
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px hsl(1, 100%, 100%) inset;
    -webkit-text-fill-color: ${colors.navy};
  };
`;

const I = styled.i`
  background-image: ${({ error }) => error ? `url(${errorLogo})` : `url(${verifiedLogo})`};
  background-size: contain;
  background-repeat: no-repeat;
  min-width: 10px;
  height: 10px;
  position: relative;
  left: -30px;
  top: 15px;
`;

const InputAndImgContainer = styled.div`
  display: flex;
  width: 100%;
`;

const inputs = {
  firstname: { label: 'Prénom', name: 'firstname', isRequired: true },
  lastname: { label: 'Nom', name: 'lastname', isRequired: true },
  phone: { label: 'Numéro de téléphone', name: 'phone', isRequired: true },
  comment: { label: 'Commentaire', name: 'comment', isRequired: true },
};


class ContactForm extends React.Component {
  constructor() {
    super();
    this.state = { isOpened: false };
    this.toggleForm = this.toggleForm.bind(this);
    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  onChange(value, name) {
    this.setState({ [name]: value });
  }

  toggleForm() {
    this.setState({ isOpened: !this.state.isOpened });
  }

  submit() {
    const { firstname, lastname, phone, comment } = this.state;
    if (!firstname || !lastname || !phone || !comment) return this.setState({ showError: true });
    this.setState({ showError: false });
    const request = { firstname, lastname, phone, comment };
    return callApi('public/contact', 'post', request)
      .then(() => { this.setState({ isOpened: false }); })
      .catch((response) => {
        this.setState({ isOpened: false });
        if (typeof Raven !== 'undefined') {
          Raven.captureException(JSON.stringify(response)); // eslint-disable-line
        } else {
          console.error(response);
        }
      });
  }

  render() {
    if (!this.state.isOpened) {
      return (
        <ButtonContainer onClick={this.toggleForm} noPadding>
          <Button isActive isFixed navy>Contacter Tilli</Button>
        </ButtonContainer>
      );
    }
    const { showError, firstname, lastname, phone, comment } = this.state;
    return (
      <Background>
        <Container>
          <Row border>
            <Title>Contacter Tilli</Title>
            <Img src={closeImg} alt="fermer" onClick={this.toggleForm} />
          </Row>

          <Label>Si vous souhaitez être contacté pour vos retouches :</Label>
          <SubLabel>Envoyez-nous un message, on vous rappellera ;-)</SubLabel>

          <Form>
            <Row>
              <FormInputContainer small hasIcon={showError}>
                <LabelFormInput>{inputs.firstname.label}</LabelFormInput>
                <InputAndImgContainer>
                  <FormInput
                    value={firstname || ''}
                    onChange={({ target: { value } }) => this.onChange(value, 'firstname')}
                    error={showError && !firstname}
                  />{showError && <I error={!firstname} />}
                </InputAndImgContainer>
              </FormInputContainer>
              <FormInputContainer small hasIcon={showError} right>
                <LabelFormInput>{inputs.lastname.label}</LabelFormInput>
                <InputAndImgContainer>
                  <FormInput
                    right
                    hasIcon={showError}
                    values={lastname || ''}
                    onChange={({ target: { value } }) => this.onChange(value, 'lastname')}
                    error={showError && !lastname}
                  />{showError && <I error={!lastname} />}
                </InputAndImgContainer>
              </FormInputContainer>
            </Row>

            <FormInputContainer hasIcon={showError}>
              <LabelFormInput>{inputs.phone.label}</LabelFormInput>
              <InputAndImgContainer>
                <FormInput
                  full
                  values={phone || ''}
                  onChange={({ target: { value } }) => this.onChange(value, 'phone')}
                  error={showError && !phone}
                />{showError && <I error={!phone} />}
              </InputAndImgContainer>
            </FormInputContainer>

            <FormInputContainer hasIcon={showError}>
              <LabelFormInput>{inputs.comment.label}</LabelFormInput>
              <InputAndImgContainer>
                <FormInput
                  full
                  multiline
                  values={comment || ''}
                  onChange={({ target: { value } }) => this.onChange(value, 'comment')}
                  error={showError && !comment}
                />{showError && <I error={!comment} />}
              </InputAndImgContainer>
            </FormInputContainer>
          </Form>


          <Row end>
            <Button navy isActive onClick={this.submit}>Envoyer</Button>
          </Row>

          <FootNote>
            Vous pouvez également nous joindre au 01.84.80.05.39.
          </FootNote>
        </Container>
      </Background>
    );
  }
}

export default ContactForm;
